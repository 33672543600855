/* eslint-disable @typescript-eslint/naming-convention */
import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useField, useFormikContext } from "formik";
import { Dropdown } from "spoton-lib";

import { MenuList } from "./MenuList.component";
import { ISelectFieldProps, ISelectValue } from "./SelectField.types";
import { useId } from "./SelectField.utils";

import styles from "./SelectField.module.scss";

const isSelectValueGuard = (value: unknown): value is ISelectValue => {
    return (
        typeof value === "object" &&
        value !== null &&
        "value" in value &&
        "label" in value
    );
};

export function SelectField<T>(props: ISelectFieldProps<T>) {
    const {
        id: idProp,
        name,
        label,
        options,
        className,
        onChange,
        value,
        placeholder,
        isDisabled,
    } = props;
    const [field, meta, helpers] = useField(name);
    const { isSubmitting, isValidating } = useFormikContext();
    const ref = useRef<any>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const nameId = useId();
    const id = idProp || `${name}-${nameId}-field`;
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    useEffect(() => {
        if (isValidating && isSubmitting) {
            helpers.setTouched(true);
            helpers.setError(meta.error);
        }
    }, [isValidating, isSubmitting]);

    const optionsMap: { [key in string]: string } = {};
    for (const option of options) {
        optionsMap[option.value] = option.label;
    }
    const verboseValue = optionsMap[value || ""] || "";

    const onMenuClosed = () => {
        setIsMenuOpen(false);
    };

    return (
        <div
            ref={containerRef}
            className={clsx(className, styles.SelectField_container)}
        >
            <Dropdown
                {...field}
                id={id}
                components={{
                    MenuList,
                }}
                ref={ref}
                menuIsOpen={isMenuOpen}
                menuShouldScrollIntoView={false}
                menuPlacement="bottom"
                menuPortalTarget={document.body}
                menuPosition="absolute"
                styles={{
                    menu: (base: Record<string, unknown>) => ({
                        ...base,
                        fontSize: "0.875rem",
                        top: "auto",
                        textAlign: "left",
                    }),
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    control: ({ outline, ...base }) => ({
                        ...base,
                        textAlign: "left",
                    }),
                    valueContainer: (base) => ({
                        ...base,
                        paddingRight: "0",
                        paddingLeft: "1rem",
                    }),
                    placeholder: (base) => ({
                        ...base,
                        fontFamily: "Poppins",
                        fontSize: 14,
                        color: "#646e8a",
                    }),
                }}
                className={className}
                value={
                    value
                        ? {
                              value: value,
                              label: verboseValue,
                          }
                        : ""
                }
                label={label}
                onBlur={() => {
                    helpers.setTouched(true);
                }}
                onChange={async (newValue) => {
                    if (isSelectValueGuard(newValue)) {
                        helpers.setValue(newValue.value);
                        helpers.setError(undefined);
                        onChange?.(newValue);
                    }
                }}
                isValid={meta.touched ? !meta.error : true}
                options={options}
                withDividers={false}
                placeholder={placeholder || ""}
                secondaryCondition={(meta.touched && meta.error) || " "}
                secondaryConditionClassName={clsx(
                    styles.SelectField_errorMessage,
                )}
                onMenuOpen={() => setIsMenuOpen(true)}
                onMenuClose={onMenuClosed}
                isSearchable={false}
                isDisabled={isDisabled || false}
            />
        </div>
    );
}

export default SelectField;
