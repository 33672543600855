import React from "react";
import { BodyText } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { IBusinessStep } from "features/application-wizard";

import styles from "../ShippinginformationForm.module.scss";

interface IShippingSubLabel {
    businessStep: IBusinessStep;
}

export const ShippingSubLabel = ({ businessStep }: IShippingSubLabel) => {
    const { businessAddress } = businessStep;

    const address = `${businessAddress.address1}, ${businessAddress.address2}${
        businessAddress.address2 && ", "
    }${businessAddress.city}, ${businessAddress.state} ${businessAddress.zip}`;

    return (
        <BodyText
            size="small"
            className={clsx(
                styles.RadioSection_label,
                styles.RadioSection_sublabel,
                "w-full",
            )}
        >
            {address}
        </BodyText>
    );
};
