import { SyntheticEvent } from "react";
import { showToast } from "@spotoninc/nexus-react";
import { AxiosError, AxiosInstance } from "axios";
import { Props } from "spoton-lib/dist/cjs/types/src/components/Toast/Toast.types";

import { t } from "i18nConfig/i18n.config";

import { retryRequest } from "./axiosRetryRequest.utils";

type DisplayErrorCardType = Omit<Props, "buttonText" | "onClose"> & {
    /** Whether to allow for retrying the request */
    allowRetry: boolean;
};

export const displayErrorCard = <T>(
    error: AxiosError<T>,
    axios: AxiosInstance,
    { onClick, allowRetry, ...restToastProps }: DisplayErrorCardType,
) => {
    return new Promise((resolve, reject) => {
        let isPending = false;
        const onClickHandler = allowRetry
            ? async (e: SyntheticEvent<Element, Event> | undefined) => {
                  if (onClick) await onClick(e);
                  if (allowRetry && !isPending) {
                      isPending = true;
                      try {
                          const data = await retryRequest(axios, error);
                          resolve(data);
                      } catch (err) {
                          reject(err);
                      }
                  }
              }
            : onClick;

        showToast({
            ...restToastProps,
            priority: "error",
            onActionClick: onClickHandler,
            actionLabel: allowRetry ? t("retryButton") : undefined,
        });
        if (!allowRetry) reject(error);
    });
};
