import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Formik, FormikProps } from "formik";

import { AnalyticEvents } from "app/utils";
import { ConfirmModal, FileUpload, StepName } from "features";
import { useSendEventHeap } from "features/hooks";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import {
    BillingSelectorRootProps,
    TabValues,
} from "./BillingSelectorRoot.types";
import { UploadDescription } from "./UploadDescription.component";
export const BillingSelectorRoot = (props: BillingSelectorRootProps) => {
    const { appStore, formikStore } = props;
    const { t } = useTranslation();

    useSendEventHeap(AnalyticEvents.BillingSelectorRootView, {
        step: StepName.BillingSelector,
    });

    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                billingStep: {
                    billingForm: { voidedCheckFile, fields },
                },
            },
            loading: {
                isDeleteCheckLoading,
                isSubmitPhotoCheckLoading,
                isToManualFormLoading,
            },
        }) => {
            return {
                voidedCheckFile: voidedCheckFile,
                fields,
                isLoading: isSubmitPhotoCheckLoading || isDeleteCheckLoading,
                isToManualFormLoading,
            };
        },
    );

    const { voidedCheckFile, isLoading } = getState();
    const [searchParams, setSearchParams] = useSearchParams({
        tab: TabValues.UploadPicture,
    });

    const onFileUploadChange = async (file: File) => {
        await appStore.asyncDispatch.uploadCapturePhoto(file);
        await appStore.asyncDispatch.submitPhotoCheck(false);
        setSearchParams({ tab: TabValues.UploadPicture });
    };

    const onFileUploadDelete = () => {
        appStore.asyncDispatch.deleteCheck();
    };

    const formRef = useRef<FormikProps<Record<string, never>>>(null);
    useEffect(() => {
        formikStore.dispatch.addRef(StepName.BillingSelector, formRef);
        return () => {
            formikStore.dispatch.removeRef(StepName.BillingSelector);
        };
    }, []);

    const [shouldShowConfirmationModal, setShouldShowConfirmModal] =
        useState<ReactNode | null>(null);

    const onSubmit = async () => {
        await appStore.asyncDispatch.toManualForm();
        setShouldShowConfirmModal(null);
    };

    const onFormSubmitBeforeConfirmation = async () => {
        if (!voidedCheckFile) {
            try {
                await new Promise((resolve, reject) => {
                    setShouldShowConfirmModal(
                        <ConfirmModal
                            isOpen
                            onClose={reject}
                            onAccept={resolve}
                            cancelLabel={t("cancelButtonLabel")}
                            acceptLabel={t("continueButton")}
                            title={t("continueWithoutVerificationTitle")}
                            description={t(
                                "continueWithoutVerificationDescription",
                            )}
                        />,
                    );
                });
                await onSubmit();
            } catch (e) {
                setShouldShowConfirmModal(null);
            }
        } else {
            await onSubmit();
        }
    };

    return (
        <div className="flex flex-col pt-3 tablet:pt-5">
            {shouldShowConfirmationModal}
            <Formik
                innerRef={formRef}
                initialValues={{}}
                onSubmit={async () => {
                    await onFormSubmitBeforeConfirmation();
                }}
                enableReinitialize={false}
            >
                {() => {
                    return (
                        <Form>
                            <div>
                                <FileUpload
                                    file={voidedCheckFile}
                                    onChange={onFileUploadChange}
                                    onDelete={onFileUploadDelete}
                                    description={<UploadDescription />}
                                    isLoading={isLoading}
                                />
                                {/*<Tabs*/}
                                {/*    value={*/}
                                {/*        searchParams.get("tab") ||*/}
                                {/*        TabValues.UploadPicture*/}
                                {/*    }*/}
                                {/*    onChange={(_, value) => {*/}
                                {/*        if (value) {*/}
                                {/*            setSearchParams({ tab: value });*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <TabsList>*/}
                                {/*        <Tab value={TabValues.UploadPicture}>*/}
                                {/*            {t("uploadPicture")}*/}
                                {/*        </Tab>*/}
                                {/*        <Tab value={TabValues.UseCamera}>*/}
                                {/*            {t("useCamera")}*/}
                                {/*        </Tab>*/}
                                {/*    </TabsList>*/}
                                {/*    <TabPanel value={TabValues.UploadPicture}>*/}
                                {/*        <div className="tablet:pt-6 pt-10">*/}
                                {/*            <FileUpload*/}
                                {/*                file={voidedCheckFile}*/}
                                {/*                onChange={onFileUploadChange}*/}
                                {/*                onDelete={onFileUploadDelete}*/}
                                {/*                description={*/}
                                {/*                    <UploadDescription />*/}
                                {/*                }*/}
                                {/*                isLoading={isLoading}*/}
                                {/*            />*/}
                                {/*        </div>*/}
                                {/*    </TabPanel>*/}
                                {/*    <TabPanel value={TabValues.UseCamera}>*/}
                                {/*        <CheckPhotoCamera*/}
                                {/*            formikStore={formikStore}*/}
                                {/*            appStore={appStore}*/}
                                {/*        />*/}
                                {/*    </TabPanel>*/}
                                {/*</Tabs>*/}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
