import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";

import {
    FederalTaxType,
    ILegalBusinessForm,
    ILegalForm,
    LegalBusinessStepDTO,
    OwnershipTypes,
} from "features/application-wizard";
import {
    DateFormat,
    DateInputField,
    ISelectValue,
    RadioField,
    ScrollToFieldError,
    SelectField,
    StepName,
    TextField,
    ZipCodeField,
} from "features/common";
import { countriesAndStates } from "features/common/constants";
import { useCountrySelect } from "features/common/hooks";
import { getTranslationFromEnumValues } from "features/utils";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { replaceAndCheckLegalValues } from "./legalBusinessForm.utils";
import { getLegalBusinessFormValidation } from "./legalBusinessForm.validation";
import { FederalTax, SubLabelIrs, SubLabelLegal } from "./subcomponents";

import styles from "../BusinessInformationForm/BusinessInformationForm.module.scss";

export const LegalBusinessForm = ({
    appStore,
    formikStore,
    federalType,
}: ILegalBusinessForm) => {
    const { t } = useTranslation();
    const ownershipOptions = getTranslationFromEnumValues(OwnershipTypes, t);

    const [dbaStateOptions, handleLegalCountrySelect] = useCountrySelect();
    const [irsStateOptions, handleIrsCountrySelect] = useCountrySelect();

    // create formik ref and add to store
    const formRef = useRef<FormikProps<LegalBusinessStepDTO> | null>(null);
    useEffect(() => {
        // create formik ref and add to formikStore
        formikStore.dispatch.addRef(StepName.BusinessLegal, formRef);
        return () => {
            formikStore.dispatch.removeRef(StepName.BusinessLegal);
        };
    }, []);

    // get businessStep from appStore and save to componentState
    const [getState] = useDerivedState(
        appStore,
        ({
            data: { businessStep },
            loading: { isCompleteActiveStepLoading },
        }) => ({
            businessStep,
            isLoading: isCompleteActiveStepLoading,
        }),
    );
    const { businessStep, isLoading } = getState();

    const {
        businessAddress,
        businessContact,
        federalTaxType,
        isLegalSameAsDba,
        isIrsSameAsLegal,
        federalTaxIdNotFormatted,
        typeOfOwnership,
    } = businessStep;

    const { businessName, firstName, lastName } = businessContact;

    const { address1, address2, city, state, zip } = businessAddress;

    const [isLegalAddressSame, setIsLegalAddressSame] =
        useState<boolean>(isLegalSameAsDba);
    const [isLegalIrsAddressSame, setIsLegalIrsAddressSame] =
        useState<boolean>(isIrsSameAsLegal);

    const [isProprietor, setIsProprietor] = useState<boolean>(
        typeOfOwnership === OwnershipTypes.IndividualSoleProprietor,
    );

    const [currentFederalTaxType, setCurrentFederalTaxType] =
        useState<FederalTaxType>(federalTaxType ?? FederalTaxType.Ein);

    const [federalTaxSubmitValue, setFederalTaxSubmitValue] = useState<string>(
        federalTaxIdNotFormatted,
    );

    const [legalForm, setLegalForm] = useState<ILegalForm>({
        businessName,
        firstName,
        lastName,
        address1,
        address2,
        city,
        state,
        zip,
    });

    const onSubmit = async (values: LegalBusinessStepDTO) => {
        const legalValues = {
            ...businessStep,
            ...values,
            federalTaxId: federalTaxSubmitValue,
        };

        const replacedDiffValues: LegalBusinessStepDTO =
            replaceAndCheckLegalValues(
                legalValues,
                businessStep,
                isLegalAddressSame,
                isLegalIrsAddressSame,
                isProprietor,
            );

        await appStore.asyncDispatch.updateLegalBusinessStep(
            replacedDiffValues,
        );
    };

    const updateStateOptions = (businessStep: LegalBusinessStepDTO) => {
        const {
            businessAddress: { country: businessCountry },
            irsAddress: { country: irsCountry },
        } = businessStep;
        handleLegalCountrySelect({
            value: businessCountry || "US",
            label: "",
        });
        handleIrsCountrySelect({
            value: irsCountry || "US",
            label: "",
        });
    };

    // whenever businessStep is updated,
    // we should make sure the state options and synced fields are updated
    useEffect(() => {
        if (!isLoading) {
            if (!businessStep.federalTaxType) {
                businessStep.federalTaxType = FederalTaxType.Ein;
            }
            if (!businessStep?.irsAddress?.country) {
                businessStep.irsAddress = {
                    ...businessStep.irsAddress,
                    country: "US",
                };
            }
            updateStateOptions(businessStep);
            formRef.current?.setValues(businessStep);
        }
    }, [businessStep]);

    const handleLegalFormChange = (name: string, value: string) => {
        setLegalForm({
            ...legalForm,
            [name]: value,
        });
    };

    return (
        <div className={styles.BusinessInformationForm}>
            <Formik
                innerRef={formRef}
                initialValues={businessStep}
                validationSchema={() =>
                    getLegalBusinessFormValidation(
                        t,
                        isLegalAddressSame,
                        isLegalIrsAddressSame,
                        currentFederalTaxType,
                        isProprietor,
                    )
                }
                onSubmit={async (values: LegalBusinessStepDTO) => {
                    await onSubmit(values);
                }}
                enableReinitialize={false}
            >
                {(formik: FormikProps<LegalBusinessStepDTO>) => {
                    return (
                        <Form>
                            <ScrollToFieldError formik={formik} />
                            <div className={clsx(styles.SectionWrapper)}>
                                <p className={styles.FederalTaxLabel}>
                                    {t("federalTaxIdLabel")}
                                </p>
                                <FederalTax
                                    formik={formik}
                                    formRef={formRef}
                                    setCurrentFederalTaxType={
                                        setCurrentFederalTaxType
                                    }
                                    setFederalTaxSubmitValue={
                                        setFederalTaxSubmitValue
                                    }
                                    federalType={federalType}
                                />
                                <div
                                    className={
                                        styles.BusinessInformationForm_row
                                    }
                                >
                                    <DateInputField
                                        className={
                                            styles.BusinessInformationForm_input
                                        }
                                        formik={formik}
                                        name="businessOpenDate"
                                        label={t("businessOpenDate")}
                                        value={formik.values.businessOpenDate}
                                        onChange={(e) => {
                                            formik.setFieldValue(
                                                "businessOpenDate",
                                                e.target.value,
                                            );
                                        }}
                                        placeholder={DateFormat.MM_DD_YYYY}
                                    />
                                    <SelectField
                                        className={clsx(
                                            styles.BusinessInformationForm_input,
                                        )}
                                        formik={formik}
                                        name="typeOfOwnership"
                                        label={t("typeOfOwnershipLabel")}
                                        value={formik.values.typeOfOwnership}
                                        options={ownershipOptions}
                                        onChange={(e: ISelectValue) => {
                                            setIsProprietor(
                                                e.value ===
                                                    OwnershipTypes.IndividualSoleProprietor,
                                            );
                                            formik.setFieldValue(
                                                "typeOfOwnership",
                                                e.value,
                                            );
                                        }}
                                    />
                                </div>
                                {isProprietor ? (
                                    <div
                                        className={
                                            styles.BusinessInformationForm_row
                                        }
                                    >
                                        <TextField
                                            className={
                                                styles.BusinessInformationForm_input
                                            }
                                            formik={formik}
                                            name="businessContact.firstName"
                                            label={t("legalFirstName")}
                                            value={
                                                formik.values?.businessContact
                                                    ?.firstName
                                            }
                                            onChange={(e) => {
                                                handleLegalFormChange(
                                                    "firstName",
                                                    e.target.value,
                                                );
                                            }}
                                        />
                                        <TextField
                                            className={
                                                styles.BusinessInformationForm_input
                                            }
                                            formik={formik}
                                            name="businessContact.lastName"
                                            label={t("legalLastName")}
                                            value={
                                                formik.values?.businessContact
                                                    ?.lastName
                                            }
                                            onChange={(e) => {
                                                handleLegalFormChange(
                                                    "lastName",
                                                    e.target.value,
                                                );
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className={
                                            styles.BusinessInformationForm_row
                                        }
                                    >
                                        <TextField
                                            className={
                                                styles.BusinessInformationForm_input
                                            }
                                            formik={formik}
                                            name="businessContact.businessName"
                                            label={t("legalBusinessLabelName")}
                                            value={
                                                formik.values?.businessContact
                                                    ?.businessName
                                            }
                                            onChange={(e) => {
                                                handleLegalFormChange(
                                                    "businessName",
                                                    e.target.value,
                                                );
                                            }}
                                        />
                                    </div>
                                )}

                                <div className={styles.SectionWrapper}>
                                    <div className={styles.SectionWrapper}>
                                        <h5
                                            className={clsx(
                                                styles.HeaderText,
                                                styles.RadioTitle,
                                            )}
                                        >
                                            {t("isLegalSameAsDbaTitle")}
                                        </h5>
                                        <div
                                            className={
                                                styles.BusinessInformationForm_radioSection
                                            }
                                        >
                                            <RadioField
                                                formik={formik}
                                                className={clsx(
                                                    styles.BusinessInformationForm_radio,
                                                )}
                                                name="isLegalSameAsDba"
                                                label={t("legalBusinessDBA")}
                                                checked={
                                                    formik.values
                                                        .isLegalSameAsDba
                                                }
                                                value={"yes"}
                                                onChange={() => {
                                                    // can only store strings/numbers for value,
                                                    // so this is custom change to set boolean
                                                    formRef.current?.setFieldValue(
                                                        "isLegalSameAsDba",
                                                        true,
                                                    );
                                                    setIsLegalAddressSame(true);
                                                }}
                                            />
                                            <SubLabelLegal
                                                businessStep={businessStep}
                                            />
                                            <RadioField
                                                formik={formik}
                                                className={clsx(
                                                    styles.BusinessInformationForm_radio,
                                                )}
                                                name="isLegalSameAsDba"
                                                label={t(
                                                    "legalBusinessEnterAddress",
                                                )}
                                                checked={
                                                    !formik.values
                                                        .isLegalSameAsDba
                                                }
                                                value={"no"}
                                                onChange={() => {
                                                    formRef.current?.setFieldValue(
                                                        "isLegalSameAsDba",
                                                        false,
                                                    );
                                                    setIsLegalAddressSame(
                                                        false,
                                                    );
                                                }}
                                            />
                                        </div>
                                        {!isLegalAddressSame && (
                                            <div
                                                className={clsx(
                                                    styles.LegalSectionWrapper,
                                                    styles.SectionWrapper_LegalDetail,
                                                )}
                                            >
                                                <div
                                                    className={
                                                        styles.BusinessInformationForm_row
                                                    }
                                                >
                                                    <TextField
                                                        className={
                                                            styles.BusinessInformationForm_input
                                                        }
                                                        formik={formik}
                                                        name="businessAddress.address1"
                                                        label={t(
                                                            "address1Label",
                                                        )}
                                                        value={
                                                            formik.values
                                                                ?.businessAddress
                                                                ?.address1
                                                        }
                                                        onChange={(e) => {
                                                            handleLegalFormChange(
                                                                "address1",
                                                                e.target.value,
                                                            );
                                                        }}
                                                    />
                                                    <TextField
                                                        className={
                                                            styles.BusinessInformationForm_input
                                                        }
                                                        formik={formik}
                                                        name="businessAddress.address2"
                                                        label={t(
                                                            "address2Label",
                                                        )}
                                                        value={
                                                            formik.values
                                                                ?.businessAddress
                                                                ?.address2 || ""
                                                        }
                                                        primaryCondition={`(${t(
                                                            "optionalLabel",
                                                        )})`}
                                                        onChange={(e) => {
                                                            handleLegalFormChange(
                                                                "address2",
                                                                e.target.value,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.BusinessInformationForm_row
                                                    }
                                                >
                                                    <div
                                                        className={clsx(
                                                            styles.BusinessInformationForm_row,
                                                            styles.BusinessInformationForm_row___subRow,
                                                            styles.BusinessInformationForm_row___fullWidthM,
                                                        )}
                                                    >
                                                        <TextField
                                                            className={clsx(
                                                                styles.BusinessInformationForm_input,
                                                                styles.BusinessInformationForm_input___subRow,
                                                                styles.BusinessInformationForm_input___fullWidthM,
                                                            )}
                                                            formik={formik}
                                                            name="businessAddress.city"
                                                            label={t(
                                                                "cityLabel",
                                                            )}
                                                            value={
                                                                formik.values
                                                                    ?.businessAddress
                                                                    ?.city
                                                            }
                                                            onChange={(e) => {
                                                                handleLegalFormChange(
                                                                    "city",
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                        />
                                                        <SelectField
                                                            formik={formik}
                                                            className={clsx(
                                                                styles.BusinessInformationForm_input,
                                                                styles.BusinessInformationForm_input___subRow,
                                                                styles.BusinessInformationForm_input___fullWidthM,
                                                            )}
                                                            name="businessAddress.state"
                                                            label={t(
                                                                "stateLabel",
                                                            )}
                                                            options={
                                                                dbaStateOptions
                                                            }
                                                            value={
                                                                formik.values
                                                                    ?.businessAddress
                                                                    ?.state ||
                                                                ""
                                                            }
                                                            onChange={(
                                                                e: ISelectValue,
                                                            ) => {
                                                                handleLegalFormChange(
                                                                    "state",
                                                                    e.value,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        className={clsx(
                                                            styles.BusinessInformationForm_row,
                                                            styles.BusinessInformationForm_row___smallSubRow,
                                                        )}
                                                    >
                                                        <ZipCodeField
                                                            formik={formik}
                                                            className={clsx(
                                                                styles.BusinessInformationForm_input,
                                                                styles.BusinessInformationForm_input___subRow,
                                                            )}
                                                            name="businessAddress.zip"
                                                            label={t(
                                                                "zipLabel",
                                                            )}
                                                            value={
                                                                formik.values
                                                                    ?.businessAddress
                                                                    ?.zip
                                                            }
                                                            onChange={(e) => {
                                                                handleLegalFormChange(
                                                                    "zip",
                                                                    e.target
                                                                        .value,
                                                                );
                                                            }}
                                                        />
                                                        <SelectField
                                                            formik={formik}
                                                            className={clsx(
                                                                styles.BusinessInformationForm_input,
                                                                styles.BusinessInformationForm_input___subRow,
                                                            )}
                                                            name="businessAddress.country"
                                                            label={t(
                                                                "countryLabel",
                                                            )}
                                                            options={
                                                                countriesAndStates
                                                            }
                                                            value={
                                                                formik.values
                                                                    ?.businessAddress
                                                                    ?.country ||
                                                                "US"
                                                            }
                                                            onChange={(
                                                                e: ISelectValue,
                                                            ) => {
                                                                handleLegalCountrySelect(
                                                                    e,
                                                                );
                                                                if (
                                                                    e.value !==
                                                                    formik
                                                                        .values
                                                                        ?.businessAddress
                                                                        ?.country
                                                                ) {
                                                                    formik.setFieldValue(
                                                                        "state",
                                                                        "",
                                                                    );
                                                                }
                                                            }}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        <h5
                                            className={clsx(
                                                styles.HeaderText,
                                                styles.RadioTitle,
                                                styles.RadioTitleSpacing,
                                            )}
                                        >
                                            {t("isIrsSameAsLegalTitle")}
                                        </h5>
                                        <div
                                            className={
                                                styles.BusinessInformationForm_radioSection
                                            }
                                        >
                                            <RadioField
                                                formik={formik}
                                                className={clsx(
                                                    styles.BusinessInformationForm_radio,
                                                )}
                                                name="isIrsSameAsLegal"
                                                label={t(
                                                    "isIrsSameAsLegalAddressPositive",
                                                )}
                                                checked={
                                                    formik.values
                                                        .isIrsSameAsLegal
                                                }
                                                value={"yes"}
                                                onChange={() => {
                                                    // can only store strings/numbers for value,
                                                    // so this is custom change to set boolean
                                                    formRef.current?.setFieldValue(
                                                        "isIrsSameAsLegal",
                                                        true,
                                                    );
                                                    setIsLegalIrsAddressSame(
                                                        true,
                                                    );
                                                }}
                                            />
                                            {isLegalAddressSame ? (
                                                <SubLabelLegal
                                                    businessStep={businessStep}
                                                />
                                            ) : (
                                                <SubLabelIrs
                                                    legalForm={legalForm}
                                                    isProprietor={isProprietor}
                                                />
                                            )}
                                            <RadioField
                                                formik={formik}
                                                className={clsx(
                                                    styles.BusinessInformationForm_radio,
                                                )}
                                                name="isIrsSameAsLegal"
                                                label={t(
                                                    "isIrsSameAsLegalAddressNegative",
                                                )}
                                                checked={
                                                    !formik.values
                                                        .isIrsSameAsLegal
                                                }
                                                value={"no"}
                                                onChange={() => {
                                                    // can only store strings/numbers for value,
                                                    // so this is custom change to set boolean
                                                    formRef.current?.setFieldValue(
                                                        "isIrsSameAsLegal",
                                                        false,
                                                    );
                                                    setIsLegalIrsAddressSame(
                                                        false,
                                                    );
                                                }}
                                            />
                                        </div>
                                        {!isLegalIrsAddressSame && (
                                            <div
                                                className={clsx(
                                                    styles.LegalSectionWrapper,
                                                    styles.SectionWrapper_LegalDetail,
                                                )}
                                            >
                                                <div
                                                    className={
                                                        styles.BusinessInformationForm_row
                                                    }
                                                >
                                                    <TextField
                                                        className={
                                                            styles.BusinessInformationForm_input
                                                        }
                                                        formik={formik}
                                                        name="irsAddress.address1"
                                                        label={t(
                                                            "address1Label",
                                                        )}
                                                        value={
                                                            formik.values
                                                                ?.irsAddress
                                                                ?.address1
                                                        }
                                                    />
                                                    <TextField
                                                        className={
                                                            styles.BusinessInformationForm_input
                                                        }
                                                        formik={formik}
                                                        name="irsAddress.address2"
                                                        label={t(
                                                            "address2Label",
                                                        )}
                                                        value={
                                                            formik.values
                                                                ?.irsAddress
                                                                ?.address2 || ""
                                                        }
                                                        primaryCondition={`(${t(
                                                            "optionalLabel",
                                                        )})`}
                                                    />
                                                </div>
                                                <div
                                                    className={
                                                        styles.BusinessInformationForm_row
                                                    }
                                                >
                                                    <div
                                                        className={clsx(
                                                            styles.BusinessInformationForm_row,
                                                            styles.BusinessInformationForm_row___subRow,
                                                            styles.BusinessInformationForm_row___fullWidthM,
                                                        )}
                                                    >
                                                        <TextField
                                                            className={clsx(
                                                                styles.BusinessInformationForm_input,
                                                                styles.BusinessInformationForm_input___subRow,
                                                                styles.BusinessInformationForm_input___fullWidthM,
                                                            )}
                                                            formik={formik}
                                                            name="irsAddress.city"
                                                            label={t(
                                                                "cityLabel",
                                                            )}
                                                            value={
                                                                formik.values
                                                                    ?.irsAddress
                                                                    ?.city
                                                            }
                                                        />
                                                        <SelectField
                                                            formik={formik}
                                                            className={clsx(
                                                                styles.BusinessInformationForm_input,
                                                                styles.BusinessInformationForm_input___subRow,
                                                                styles.BusinessInformationForm_input___fullWidthM,
                                                            )}
                                                            name="irsAddress.state"
                                                            label={t(
                                                                "stateLabel",
                                                            )}
                                                            options={
                                                                irsStateOptions
                                                            }
                                                            value={
                                                                formik.values
                                                                    ?.irsAddress
                                                                    ?.state ||
                                                                ""
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={clsx(
                                                            styles.BusinessInformationForm_row,
                                                            styles.BusinessInformationForm_row___smallSubRow,
                                                        )}
                                                    >
                                                        <ZipCodeField
                                                            formik={formik}
                                                            className={clsx(
                                                                styles.BusinessInformationForm_input,
                                                                styles.BusinessInformationForm_input___subRow,
                                                            )}
                                                            name="irsAddress.zip"
                                                            label={t(
                                                                "zipLabel",
                                                            )}
                                                            value={
                                                                formik.values
                                                                    ?.irsAddress
                                                                    ?.zip
                                                            }
                                                        />
                                                        <SelectField
                                                            formik={formik}
                                                            className={clsx(
                                                                styles.BusinessInformationForm_input,
                                                                styles.BusinessInformationForm_input___subRow,
                                                            )}
                                                            name="irsAddress.country"
                                                            label={t(
                                                                "countryLabel",
                                                            )}
                                                            options={
                                                                countriesAndStates
                                                            }
                                                            value={
                                                                formik.values
                                                                    ?.irsAddress
                                                                    ?.country ||
                                                                "US"
                                                            }
                                                            onChange={(
                                                                e: ISelectValue,
                                                            ) => {
                                                                handleIrsCountrySelect(
                                                                    e,
                                                                );
                                                                if (
                                                                    e.value !==
                                                                    formik
                                                                        .values
                                                                        ?.irsAddress
                                                                        ?.country
                                                                ) {
                                                                    formik.setFieldValue(
                                                                        "irsState",
                                                                        "",
                                                                    );
                                                                }
                                                            }}
                                                            isDisabled={true}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default LegalBusinessForm;
