import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Formik, FormikProps } from "formik";

import { ZipCodeField } from "features/common";
import { getSuggestedLocalTaxes } from "features/utils";

import { SuggestedTaxes } from "../../../ShippingInformationForm.types";
import { AutoSubmit } from "../AutoSubmit.component";
import { CommonModal } from "../CommonModal.component";
import { areSuggestedTaxesFound } from "../helpers";
import { zipChangeValidation } from "../ZipChange.validation";
import { SuggestedTaxSearchResult } from "./Subcomponents";

type ZipChangeModalProps = {
    onClose: () => void;
    isOpen: boolean;
    initialZip: string;
    onZipChange: (zip: string, suggestedTaxes: SuggestedTaxes) => Promise<void>;
    isConfirmLoading: boolean;
};

export const ZipChangeModal = ({
    onClose,
    isOpen,
    initialZip,
    onZipChange,
    isConfirmLoading,
}: ZipChangeModalProps) => {
    const { t } = useTranslation();
    const [taxRates, setTaxRates] = useState<SuggestedTaxes | null>(null);
    const isFound = areSuggestedTaxesFound(taxRates);
    const [zip, setZip] = useState<string>(initialZip);
    const [isLoadingSuggestions, setIsLoadingSuggestions] = useState(false);

    const formikRef = useRef<FormikProps<{ zip: string }> | null>(null);

    const updateSuggestions = async (zip: string) => {
        try {
            setIsLoadingSuggestions(true);
            const data = await getSuggestedLocalTaxes(zip);
            setTaxRates(data);
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoadingSuggestions(false);
            setZip(zip);
        }
    };

    return (
        <CommonModal
            isOpen={isOpen}
            onClose={onClose}
            title={t("changeZipCodeTitle")}
            subtitle={t("enterNewZip")}
            onConfirm={async () => {
                if (taxRates && formikRef.current?.isValid) {
                    await onZipChange(zip, taxRates);
                    onClose();
                }
            }}
            isConfirmLoading={isConfirmLoading}
        >
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-4">
                    <Formik
                        innerRef={formikRef}
                        initialValues={{ zip }}
                        onSubmit={async ({ zip }) => {
                            await updateSuggestions(zip);
                        }}
                        validationSchema={zipChangeValidation(t)}
                    >
                        {(formik) => {
                            const fieldMeta = formik.getFieldMeta("zip");
                            const errorMessage =
                                fieldMeta.error ??
                                (!isFound && !!zip && !isLoadingSuggestions
                                    ? t("cantFindTaxRate")
                                    : undefined) ??
                                "";
                            return (
                                <Form>
                                    <ZipCodeField
                                        errorMessage={errorMessage}
                                        value={formik.values.zip}
                                        formik={formik}
                                        name="zip"
                                        label=""
                                        isValid={!errorMessage}
                                        onChange={() => {
                                            setTaxRates(null);
                                        }}
                                        isClearable
                                    />
                                    <AutoSubmit />
                                    {!fieldMeta.error && (
                                        <SuggestedTaxSearchResult
                                            taxRates={taxRates}
                                            isLoading={isLoadingSuggestions}
                                        />
                                    )}
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </CommonModal>
    );
};
