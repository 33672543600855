import React, { useEffect, useRef, useState } from "react";
import { showToast } from "@spotoninc/nexus-react";
import { Button } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";
import { TFunction } from "i18next";
import * as Yup from "yup";

import { IPageComponentProps } from "app/types";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { IOwnerType } from "features/application-wizard";
import { RadioField, ScrollToFieldError, StepName } from "features/common";
import { defaultOwner } from "features/store/AppStore/defaultState";
import { useTranslation } from "i18nConfig";
import { Namespace } from "i18nConfig/translation.types";
import { useDerivedState } from "store/hooks";

import { OwnerStatus } from "../ApplicantForm";
import { ownerValidationSchema } from "../common/owners.utils";
import OwnerItem from "./OwnerItem/OwnerItem.component";
import { IOwnersStep, ISubmitValues, MoreThan25 } from "./OwnersForm.types";

import styles from "./OwnersForm.module.scss";

// schema for entire form
export const ownersStepValidationSchema = (
    t: TFunction<Namespace, undefined, Namespace>,
    percentageInApplicantStep: number,
    ownerStatus?: OwnerStatus,
) =>
    Yup.object().shape({
        owners: Yup.array().of(
            ownerValidationSchema({
                t,
                percentageFromOtherStep: percentageInApplicantStep,
                isAdditionalOwners: true,
                ownerStatus,
            }),
        ),
    });

const isAddingOwnerAllowed = (
    applicantOwnershipPercentage: number,
    possibleOwnershipPercentage: number,
    numberOfAdditionalOwners: number,
) => {
    const maxAdditionalOwners = Math.floor(
        (100 - applicantOwnershipPercentage) / 25,
    );
    return (
        100 - possibleOwnershipPercentage >= 25 &&
        numberOfAdditionalOwners < maxAdditionalOwners
    );
};

export const OwnersForm = ({ appStore, formikStore }: IPageComponentProps) => {
    const { t } = useTranslation();
    // create formik ref and add to formikStore
    const formRef = useRef<FormikProps<IOwnersStep> | null>(null);
    useEffect(() => {
        formikStore.dispatch.addRef(StepName.Owners, formRef);
        return () => {
            formikStore.dispatch.removeRef(StepName.Owners);
        };
    }, []);

    // get ownersStep from appStore and save to componentState
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                applicantStep: { applicant },
                ownersStep,
                profile: { firstName },
            },
        }) => {
            return {
                applicant,
                ownersStep,
                firstName,
            };
        },
    );
    const { applicant, ownersStep } = getState();

    const { isMoreThan25 } = ownersStep;
    const applicantOwnershipPercentage = applicant.ownershipPercentage || 0;
    const isAdditionalOwnersRequired =
        applicantOwnershipPercentage < 25 && applicant.isResponsibleParty;
    const [possibleOwnershipPercentage, setPossibleOwnershipPercentage] =
        useState<number>(applicantOwnershipPercentage);

    const initialSubmitValues = ownersStep.owners.map(
        (owner: IOwnerType, index: number): ISubmitValues => {
            const ssn = owner.ssnNotFormatted || "";
            return { value: ssn, index };
        },
    );

    const [ssnSubmitValues, setSsnSubmitValues] =
        useState<ISubmitValues[]>(initialSubmitValues);

    const onAddOwner = () => {
        if (!formRef.current) return;
        formRef.current.values.owners.push(defaultOwner());
        appStore.dispatch.setOwners(formRef.current.values.owners);
    };

    const onRemoveOwner = async (index: number) => {
        if (!formRef.current) return;
        formRef.current.values.owners[index].isDeleted = true;
        appStore.dispatch.setOwners(formRef.current.values.owners);
    };

    const renderOwnerItems = ({
        formik,
    }: {
        formik: FormikProps<IOwnersStep>;
    }) => {
        const ownerItems: React.ReactNode[] = [];
        let sequenceNumber = 1;
        const currentOwners = formRef.current?.values.owners;
        const numberOfOwners =
            currentOwners?.filter((owner) => !owner.isDeleted).length || 0;
        let possibleOwnershipPercentage = applicantOwnershipPercentage;
        currentOwners?.forEach((owner, i) => {
            if (!owner.isDeleted) {
                possibleOwnershipPercentage += Math.max(
                    Number(owner.ownershipPercentage) || 0,
                    25,
                );
                setPossibleOwnershipPercentage(possibleOwnershipPercentage);
                ownerItems.push(
                    <OwnerItem
                        key={i}
                        i={i}
                        sequenceNumber={sequenceNumber}
                        formik={formik}
                        onRemoveOwner={onRemoveOwner}
                        isDeletable={numberOfOwners > 1}
                        ssnSubmitValues={ssnSubmitValues}
                        setSsnSubmitValues={setSsnSubmitValues}
                    />,
                );
                sequenceNumber += 1;
            }
        });

        return ownerItems;
    };
    const shouldShowAddOwnerButton = isAddingOwnerAllowed(
        applicantOwnershipPercentage,
        possibleOwnershipPercentage,
        ownersStep.owners.filter((owner) => !owner.isDeleted).length,
    );

    const onMoreThan25Change = (moreThan25: MoreThan25) => () => {
        const values = formRef?.current?.values;
        if (moreThan25 === MoreThan25.Yes && values?.owners.length === 0) {
            values.owners.push(defaultOwner());
        }
        const ownersStep = {
            ...values,
            isMoreThan25: moreThan25 === MoreThan25.Yes,
        } as IOwnersStep;
        appStore.dispatch.setOwnersStep(ownersStep);
    };

    const onSubmit = async (values: IOwnersStep) => {
        const ownersWithoutDeleted = values.isMoreThan25
            ? values.owners.filter((owner) => !owner.isDeleted)
            : [];
        const numberOfResponsibleParties = ownersWithoutDeleted.reduce(
            (acc, obj) => acc + (obj.isResponsibleParty ? 1 : 0),
            applicant.isResponsibleParty ? 1 : 0,
        );

        if (numberOfResponsibleParties === 0) {
            showToast({
                priority: "error",
                content: t("youMustDesignateAuthorizedPerson"),
            });
            return;
        } else if (numberOfResponsibleParties > 4) {
            showToast({
                priority: "error",
                content: t("max4OwnersCanBeResponsibleParty"),
            });
            return;
        }
        if (ssnSubmitValues.length) {
            ssnSubmitValues.forEach(({ value, index }: ISubmitValues) => {
                values.owners[index].ssn = value;
            });
        }
        await appStore.asyncDispatch.updateOwnersStep(values);
    };

    // update Formik values when ownerStep is updated,
    // (normally would use enableReinitialize, but this happens too often)
    useEffect(() => {
        formRef.current?.setValues(ownersStep);
    }, [ownersStep]);

    useEffect(() => {
        if (isAdditionalOwnersRequired) {
            onMoreThan25Change(MoreThan25.Yes)();
        }
    }, [isAdditionalOwnersRequired]);

    return (
        <div className={clsx(styles.OwnersForm)}>
            <Formik
                innerRef={formRef}
                initialValues={ownersStep}
                validationSchema={() =>
                    isMoreThan25
                        ? ownersStepValidationSchema(
                              t,
                              applicantOwnershipPercentage,
                              OwnerStatus.PartOwner,
                          )
                        : Yup.object()
                }
                onSubmit={async (values: IOwnersStep) => {
                    await onSubmit(values);
                }}
            >
                {(formik: FormikProps<IOwnersStep>) => {
                    return (
                        <Form>
                            <ScrollToFieldError formik={formik} />
                            {!isAdditionalOwnersRequired && (
                                <div
                                    className={clsx(
                                        styles.OwnersForm_radioSection,
                                    )}
                                >
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.OwnersForm_radio,
                                        )}
                                        name="isMoreThan25"
                                        label={t("yes")}
                                        checked={!!formik.values.isMoreThan25}
                                        value={MoreThan25.Yes}
                                        onChange={onMoreThan25Change(
                                            MoreThan25.Yes,
                                        )}
                                    />
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.OwnersForm_radio,
                                        )}
                                        name="isMoreThan25"
                                        label={t("no")}
                                        checked={!formik.values.isMoreThan25}
                                        value={MoreThan25.No}
                                        onChange={onMoreThan25Change(
                                            MoreThan25.No,
                                        )}
                                    />
                                </div>
                            )}
                            {isMoreThan25 && (
                                <div
                                    className={clsx(styles.OwnersForm_details)}
                                >
                                    {!isAdditionalOwnersRequired && (
                                        <h5
                                            className={clsx(
                                                styles.OwnersForm_detailsHeader,
                                            )}
                                        >
                                            {t("addEachPersonWithMoreThan25")}
                                        </h5>
                                    )}
                                    {renderOwnerItems({ formik })}
                                    {shouldShowAddOwnerButton && (
                                        <Button
                                            onClick={() => {
                                                onAddOwner();
                                            }}
                                            variant="secondary"
                                            type="button"
                                            className={
                                                styles.OwnersForm_addOwnerBtn
                                            }
                                            data-testid="add-additional-owner-btn"
                                            {...getAnalyticProps(
                                                AnalyticElements.AddAdditionalOwnerButton,
                                                { step: StepName.Owners },
                                            )}
                                        >
                                            {t("addOwnerBtn")}
                                        </Button>
                                    )}
                                </div>
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
