import React, { useEffect, useRef } from "react";
import { Form, Formik, FormikProps } from "formik";

import { ScrollToFieldError } from "features";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { StepName } from "../Stepper";
import { TextField } from "../TextField";
import {
    IManualLinkAccountForm,
    IManualLinkAccountFormData,
} from "./ManualLinkAccountForm.types";
import {
    manualLinkAccountFormikInitialData,
    manualLinkValidationSchema,
} from "./ManualLinkAccountForm.utils";

import styles from "./ManualLinkAccountForm.module.scss";

export const ManualLinkAccountForm = ({
    formikStore,
    onFormSubmit,
    appStore,
}: IManualLinkAccountForm) => {
    const { t } = useTranslation();
    const ref = useRef<FormikProps<IManualLinkAccountFormData> | null>(null);
    const [getState] = useDerivedState(
        appStore,
        ({
            data: {
                billingStep: {
                    billingForm: { fields },
                },
            },
        }) => {
            return {
                fields,
            };
        },
    );
    const { fields } = getState();

    useEffect(() => {
        formikStore.dispatch.addRef(StepName.BillingForm, ref);
        return () => {
            formikStore.dispatch.removeRef(StepName.BillingForm);
        };
    }, []);

    const onNumberChange = <T,>(
        e: React.ChangeEvent<HTMLInputElement>,
        field: T extends Record<string, unknown> ? keyof T & string : string,
        pattern: RegExp,
    ) => {
        const value = e.target.value;
        if (value === "" || pattern.test(value)) {
            ref.current?.setFieldValue(field, value);
        } else {
            ref.current?.setFieldValue(
                field,
                ref.current?.values[field as keyof IManualLinkAccountFormData],
            );
        }
    };

    return (
        <div
            className={styles.ManualLinkAccountModal}
            id="manual-link-account-modal"
        >
            <div className={styles.ManualLinkAccountModal_content}>
                <Formik
                    innerRef={ref}
                    initialValues={{
                        ...manualLinkAccountFormikInitialData,
                        ...fields,
                    }}
                    validationSchema={() => manualLinkValidationSchema(t)}
                    onSubmit={async (values) => {
                        await onFormSubmit(values);
                    }}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <ScrollToFieldError formik={formik} />
                                <div className="flex flex-col gap-[13px] pb-2 pt-7">
                                    <div className="flex flex-col tablet:flex-row gap-6">
                                        <TextField
                                            dataDdPrivacy="mask-user-input"
                                            trim="start"
                                            className="flex-1 text-sm py-0"
                                            formik={formik}
                                            name="routingNumber"
                                            label={t("routingNumberLabel")}
                                            value={formik.values.routingNumber}
                                            placeholder={t(
                                                "routingNumberPlaceholder",
                                            )}
                                            onChange={(e) =>
                                                onNumberChange<IManualLinkAccountFormData>(
                                                    e,
                                                    "routingNumber",
                                                    /^\d{0,9}$/,
                                                )
                                            }
                                            inputMode="numeric"
                                        />
                                        <TextField
                                            dataDdPrivacy="mask-user-input"
                                            trim="start"
                                            className="flex-1 text-sm [&_input]:pr-2  py-0"
                                            formik={formik}
                                            name="accountNumber"
                                            label={t("accountNumberLabel")}
                                            value={formik.values.accountNumber}
                                            placeholder={t(
                                                "accountNumberPlaceholder",
                                            )}
                                            onChange={(e) =>
                                                onNumberChange<IManualLinkAccountFormData>(
                                                    e,
                                                    "accountNumber",
                                                    /^\d{0,17}$/,
                                                )
                                            }
                                            inputMode="numeric"
                                        />
                                    </div>
                                    <div className="flex flex-col tablet:flex-row gap-6">
                                        <TextField
                                            dataDdPrivacy="mask-user-input"
                                            trim="start"
                                            className="flex-1 text-sm py-0"
                                            formik={formik}
                                            name="accountName"
                                            label={t("businessNameLabel")}
                                            value={formik.values.accountName}
                                        />
                                        <TextField
                                            dataDdPrivacy="mask-user-input"
                                            trim="start"
                                            className="flex-1 text-sm py-0"
                                            formik={formik}
                                            name="bankName"
                                            label={t("bankNameLabel")}
                                            value={formik.values.bankName}
                                        />
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};
