import React from "react";
import { BodyText, Button } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { StepPageLayout } from "app/components";
import { IPageComponentProps } from "app/types";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { Breakpoint, StepName, useBreakpoint } from "features";
import { useStepNavigate } from "features/utils";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { AlmostDoneImages } from "./AlmostDoneImages";

import styles from "./AlmostDonePage.module.scss";

export const AlmostDonePage = ({
    appStore,
    formikStore,
}: IPageComponentProps) => {
    const { t } = useTranslation();

    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);

    const getState = useDerivedState(
        appStore,
        ({
            data: {
                progressBlock: {
                    view: { activeStep },
                },
                config: { isRestaurant },
            },
        }) => ({ activeStep, isRestaurant }),
    )[0];
    const { activeStep } = getState();

    const { goForward } = useStepNavigate({ appStore });

    const ContinueButton = () => (
        <Button
            onClick={async () => goForward({ formikStore })}
            variant="primary"
            className={styles.ContentWrapper_button}
            {...getAnalyticProps(AnalyticElements.ContinueButton, {
                step: StepName.AlmostDone,
            })}
        >
            {t("continueButton")}
        </Button>
    );

    return (
        <StepPageLayout
            appStore={appStore}
            id="almost-done-step"
            title={""}
            isLoading={!activeStep}
            formikStore={formikStore}
            width="wide"
            isFooterHidden={!isLowerThanTablet}
            footerClassName="shadow-none"
            className={clsx(
                "[&>.StepPageLayout>.StepContentContainer>.StepWrapper]:pb-0",
                "[&>.StepPageLayout>.StepContentContainer>.StepWrapper>.TitlesAndContent>.StepContent]:pb-0",
            )}
        >
            <div className={styles.ContentWrapper}>
                <div className={styles.ContentWrapper_titles}>
                    <h1
                        className={clsx(
                            styles.ContentWrapper_title,
                            "text-[48px] font-bold",
                        )}
                    >
                        {t("youreAlmostDoneTitle")}
                    </h1>
                    <BodyText size="medium">
                        {t("youreAlmostDoneDescription")}
                    </BodyText>
                    {!isLowerThanTablet && <ContinueButton />}
                </div>
                <AlmostDoneImages isRestaurant />
            </div>
        </StepPageLayout>
    );
};
