enum CommonEndpoints {
    Config = "/api/common/config",
    SetStep = "/api/common/config/set-step",
    TcVersion = "/api/common/tc-version",
    TcFile = "/api/common/tc-file",
}

enum SignupEndpoints {
    ApplicantStep = "/api/signup/owner-step/applicant",
    OwnersStep = "/api/signup/owner-step/additional",
    BusinessStep = "/api/signup/business-step",
    BillingStepUploadCheck = "/api/signup/billing-step/upload-check/",
    BillingStepDeleteCheck = "/api/signup/billing-step/delete-check/",
    BillingStepForm = "/api/signup/billing-step/form/",
    BillingStepFormCheck = "/api/signup/billing-step/voided-check/",
    BillingStepManual = "/api/signup/billing-step/manual-connect/",
    BillingStepSuccessDeleteLinkedAccount = "/api/signup/billing-step/success/delete-linked-account/",
    BillingStepSuccess = "/api/signup/billing-step/success/",
    PlatformStepLineItems = "/api/signup/platform-step-line-items",
    PlatformStepProcessingItems = "/api/signup/platform-step-processing-items",
    ShippingInfoStep = "/api/signup/shipping-info-step",
    SignatureStep = "/api/signup/signup-step",
    TaxRatesByZip = "/api/signup/shipping-info-step/tax-rates/get-tax-rate-by-zipcode",
    AdditionalTaxRates = "/api/signup/shipping-info-step/tax-rates/",
    TermsAndConditionsStep = "/api/signup/signup-step-v2",
    AlmostDoneStep = "/api/signup/almost-done-step",
    FasterFunding = "/api/signup/astra-enrollment",
    SuccessToWelcomeSetup = "/api/signup/create-welcome-setup-application",
}

enum AuthEndpoints {
    PlaidGetLinkToken = "/api/auth/plaid-get-link-token",
    PlaidAccountRegister = "/api/auth/plaid-account-register",
    Token = "/api/auth/token",
}

enum SiteSurveyEndpoints {
    SiteSurvey = "/api/site-survey",
}

export const API_ENDPOINTS = {
    common: CommonEndpoints,
    signup: SignupEndpoints,
    auth: AuthEndpoints,
    siteSurvey: SiteSurveyEndpoints,
} as const;
