import React, { useEffect, useRef } from "react";
import { BodyText } from "@spotoninc/nexus-react";
import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";
import { Divider } from "spoton-lib";

import { PhoneInputField, RadioField, ZipCodeField } from "features";
import {
    DollarField,
    ISelectValue,
    ScrollToFieldError,
    SelectField,
    StepName,
    TextField,
} from "features/common";
import { countriesAndStates } from "features/common/constants";
import { useCountrySelect } from "features/common/hooks";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import {
    EmployeeRange,
    GeneralBusinessStepDTO,
} from "../BusinessInformationForm.types";
import { IFormProps } from "../BusinessInformationForm.types";
import { getBusinessInformationValidationSchema } from "./index";

import styles from "../BusinessInformationForm.module.scss";

// Component
export const BusinessInformationForm = ({
    appStore,
    formikStore,
}: IFormProps) => {
    // translations
    const { t } = useTranslation();

    // create formik ref and add to store
    const formRef = useRef<FormikProps<GeneralBusinessStepDTO> | null>(null);
    useEffect(() => {
        // create formik ref and add to formikStore
        formikStore.dispatch.addRef(StepName.Business, formRef);
        return () => {
            formikStore.dispatch.removeRef(StepName.Business);
        };
    }, []);

    // get businessStep from appStore and save to componentState
    const [getState] = useDerivedState(
        appStore,
        ({
            data: { businessStep, shippingStep, applicantStep },
            loading: { isCompleteActiveStepLoading },
        }) => ({
            applicantStep,
            isLoading: isCompleteActiveStepLoading,
            businessStep,
            shippingStep,
        }),
    );
    const { businessStep, isLoading } = getState();

    const [businessStateOptions, handleBusinessCountrySelect] =
        useCountrySelect();

    const updateStateOptions = (businessStep: GeneralBusinessStepDTO) => {
        const {
            dbaAddress: { country: dbaCountry },
        } = businessStep;
        handleBusinessCountrySelect({
            value: dbaCountry || "US",
            label: "",
        });
    };

    const onSubmit = async (values: GeneralBusinessStepDTO) => {
        await appStore.asyncDispatch.updateBusinessGeneralStep(values);
    };

    // whenever businessStep is updated,
    // we should make sure the state options and synced fields are updated
    useEffect(() => {
        if (!isLoading) {
            updateStateOptions(businessStep);
            formRef.current?.setValues(businessStep);
        }
    }, [businessStep]);

    return (
        <div className={styles.BusinessInformationForm}>
            <Formik
                innerRef={formRef}
                initialValues={businessStep}
                validationSchema={() =>
                    getBusinessInformationValidationSchema(t)
                }
                onSubmit={async (values: GeneralBusinessStepDTO) => {
                    await onSubmit(values);
                }}
                enableReinitialize={false}
            >
                {(formik: FormikProps<GeneralBusinessStepDTO>) => {
                    return (
                        <Form>
                            <ScrollToFieldError formik={formik} />
                            <div
                                className={clsx(
                                    styles.SectionWrapper,
                                    styles.SectionWrapper_LegalDetail,
                                )}
                            >
                                <div
                                    className={
                                        styles.BusinessInformationForm_row
                                    }
                                >
                                    <TextField
                                        className={clsx(
                                            styles.BusinessInformationForm_inputOverflow,
                                            styles.BusinessInformationForm_input,
                                        )}
                                        formik={formik}
                                        name="dbaContact.businessName"
                                        label={t("legalBusinessNameLabel")}
                                        value={
                                            formik.values?.dbaContact
                                                ?.businessName
                                        }
                                    />
                                </div>
                                <div
                                    className={
                                        styles.BusinessInformationForm_row
                                    }
                                >
                                    <TextField
                                        className={
                                            styles.BusinessInformationForm_input
                                        }
                                        formik={formik}
                                        name="dbaAddress.address1"
                                        label={t("legalAddress1Label")}
                                        value={
                                            formik.values?.dbaAddress?.address1
                                        }
                                    />
                                    <TextField
                                        className={
                                            styles.BusinessInformationForm_input
                                        }
                                        formik={formik}
                                        name="dbaAddress.address2"
                                        label={t("legalAddress2Label")}
                                        value={
                                            formik.values?.dbaAddress
                                                ?.address2 || ""
                                        }
                                        primaryCondition={`(${t(
                                            "optionalLabel",
                                        )})`}
                                    />
                                </div>
                                <div
                                    className={
                                        styles.BusinessInformationForm_row
                                    }
                                >
                                    <div
                                        className={clsx(
                                            styles.BusinessInformationForm_row,
                                            styles.BusinessInformationForm_row___subRow,
                                            styles.BusinessInformationForm_row___fullWidthM,
                                        )}
                                    >
                                        <TextField
                                            className={clsx(
                                                styles.BusinessInformationForm_input,
                                                styles.BusinessInformationForm_input___subRow,
                                                styles.BusinessInformationForm_input___fullWidthM,
                                            )}
                                            formik={formik}
                                            name="dbaAddress.city"
                                            label={t("cityLabel")}
                                            value={
                                                formik.values?.dbaAddress?.city
                                            }
                                        />
                                        <SelectField
                                            formik={formik}
                                            className={clsx(
                                                styles.BusinessInformationForm_input,
                                                styles.BusinessInformationForm_input___subRow,
                                                styles.BusinessInformationForm_input___fullWidthM,
                                            )}
                                            name="dbaAddress.state"
                                            label={t("stateLabel")}
                                            options={businessStateOptions}
                                            value={
                                                formik.values?.dbaAddress
                                                    ?.state || ""
                                            }
                                        />
                                    </div>
                                    <div
                                        className={clsx(
                                            styles.BusinessInformationForm_row,
                                            styles.BusinessInformationForm_row___smallSubRow,
                                        )}
                                    >
                                        <ZipCodeField
                                            formik={formik}
                                            className={clsx(
                                                styles.BusinessInformationForm_input,
                                                styles.BusinessInformationForm_input___subRow,
                                            )}
                                            name="dbaAddress.zip"
                                            label={t("zipLabel")}
                                            value={
                                                formik.values?.dbaAddress?.zip
                                            }
                                        />
                                        <SelectField
                                            formik={formik}
                                            className={clsx(
                                                styles.BusinessInformationForm_input,
                                                styles.BusinessInformationForm_input___subRow,
                                            )}
                                            name="dbaAddress.country"
                                            label={t("countryLabel")}
                                            options={countriesAndStates}
                                            value={
                                                formik.values?.dbaAddress
                                                    ?.country || "US"
                                            }
                                            onChange={(e: ISelectValue) => {
                                                handleBusinessCountrySelect(e);
                                                if (
                                                    e.value !==
                                                    formik.values?.dbaAddress
                                                        ?.country
                                                ) {
                                                    formik.setFieldValue(
                                                        "dbaState",
                                                        "",
                                                    );
                                                }
                                            }}
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>
                                <div
                                    className={
                                        styles.BusinessInformationForm_row
                                    }
                                >
                                    <PhoneInputField
                                        className={
                                            styles.BusinessInformationForm_input
                                        }
                                        formik={formik}
                                        name="dbaContact.phone"
                                        label={t("businessPhoneLabel")}
                                        value={formik.values?.dbaContact?.phone}
                                    />
                                    <TextField
                                        className={
                                            styles.BusinessInformationForm_input
                                        }
                                        formik={formik}
                                        name="dbaContact.email"
                                        label={t("principalEmailLabel")}
                                        value={formik.values?.dbaContact?.email}
                                        inputMode="email"
                                        autoCapitalize="off"
                                    />
                                </div>
                                <div
                                    className={
                                        styles.BusinessInformationForm_row
                                    }
                                >
                                    <TextField
                                        className={
                                            styles.BusinessInformationForm_input
                                        }
                                        formik={formik}
                                        name="website"
                                        label={t("websiteLabel")}
                                        value={formik.values.website || ""}
                                        primaryCondition={`(${t(
                                            "optionalLabel",
                                        )})`}
                                        inputMode="url"
                                        autoCapitalize="off"
                                    />
                                </div>
                            </div>
                            <div
                                className={clsx(
                                    styles.SectionWrapper,
                                    styles.SectionWrapper_LegalDetail,
                                )}
                            >
                                <h5 className="pb-5 font-bold text-xl">
                                    {t("employeesTitle")}
                                </h5>
                                <div
                                    className={clsx(
                                        styles.BusinessInformationForm_radioSection,
                                    )}
                                >
                                    {Object.values(EmployeeRange).map(
                                        (employeeRange) => (
                                            <RadioField
                                                key={employeeRange}
                                                formik={formik}
                                                className={clsx(
                                                    styles.BusinessInformationForm_radio,
                                                    styles.BusinessInformationForm_radioQuestion,
                                                )}
                                                name="employeeCount"
                                                label={employeeRange}
                                                checked={
                                                    formik.values
                                                        .employeeCount ===
                                                    employeeRange
                                                }
                                                onChange={() => {
                                                    formRef.current?.setFieldValue(
                                                        "employeeCount",
                                                        employeeRange,
                                                    );
                                                }}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                            <div
                                className={clsx(
                                    styles.SectionWrapper,
                                    styles.SectionWrapper_LegalDetail,
                                )}
                            >
                                <h5 className="pb-5 font-bold text-xl">
                                    {t("salesTitle")}
                                </h5>
                                <div className="pb-8">
                                    <BodyText
                                        size="medium"
                                        className="max-w-[430px] font-normal"
                                    >
                                        {t("areYouAcceptingCards")}
                                    </BodyText>
                                    <div
                                        className={clsx(
                                            styles.BusinessInformationForm_radioSectionHorizontal,
                                            "pt-4",
                                        )}
                                    >
                                        <RadioField
                                            formik={formik}
                                            className={clsx(
                                                styles.BusinessInformationForm_radio,
                                            )}
                                            name="isAcceptingCards"
                                            label={t("yes")}
                                            checked={
                                                formik.values.isAcceptingCards
                                            }
                                            value={"Yes"}
                                            onChange={() =>
                                                formik.setFieldValue(
                                                    "isAcceptingCards",
                                                    true,
                                                )
                                            }
                                        />
                                        <RadioField
                                            formik={formik}
                                            className={clsx(
                                                styles.BusinessInformationForm_radio,
                                            )}
                                            name="isAcceptingCards"
                                            label={t("no")}
                                            checked={
                                                !formik.values.isAcceptingCards
                                            }
                                            value={"No"}
                                            onChange={() =>
                                                formik.setFieldValue(
                                                    "isAcceptingCards",
                                                    false,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="pb-8">
                                    <BodyText
                                        size="medium"
                                        className="max-w-[430px]"
                                    >
                                        {t("doYouExpectLessThan1MilAmex")}
                                    </BodyText>
                                    <div
                                        className={clsx(
                                            styles.BusinessInformationForm_radioSectionHorizontal,
                                            "pt-4",
                                        )}
                                    >
                                        <RadioField
                                            formik={formik}
                                            className={clsx(
                                                styles.BusinessInformationForm_radio,
                                            )}
                                            name="isAmexUnder1Mil"
                                            label={t("yes")}
                                            checked={
                                                formik.values.isAmexUnder1Mil
                                            }
                                            value={"Yes"}
                                            onChange={() =>
                                                formik.setFieldValue(
                                                    "isAmexUnder1Mil",
                                                    true,
                                                )
                                            }
                                        />
                                        <RadioField
                                            formik={formik}
                                            className={clsx(
                                                styles.BusinessInformationForm_radio,
                                            )}
                                            name="isAmexUnder1Mil"
                                            label={t("no")}
                                            checked={
                                                !formik.values.isAmexUnder1Mil
                                            }
                                            value={"No"}
                                            onChange={() =>
                                                formik.setFieldValue(
                                                    "isAmexUnder1Mil",
                                                    false,
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <Divider className="border-b-0" />
                                <div className="flex flex-col gap-6 pt-8 pb-2 tablet:gap-4">
                                    <div className="flex flex-col gap-3 items-start justify-between tablet:flex-row tablet:gap-10 tablet:items-center">
                                        <BodyText
                                            size="medium"
                                            className="font-normal"
                                        >
                                            {t("whatIsAverageMonthlySales")}
                                        </BodyText>
                                        <DollarField
                                            formik={formik}
                                            name={"requestedMonthlySales"}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-3 items-start justify-between tablet:flex-row tablet:gap-10 tablet:items-center">
                                        <BodyText size="medium">
                                            {t("whatIsAverageOrderSize")}
                                        </BodyText>
                                        <DollarField
                                            formik={formik}
                                            name={"averageTicket"}
                                        />
                                    </div>
                                    <div className="flex flex-col gap-3 items-start justify-between tablet:flex-row tablet:gap-10 tablet:items-center">
                                        <BodyText
                                            size="medium"
                                            className="font-normal"
                                        >
                                            {t(
                                                "whatIsLargestTransactionYouExpect",
                                            )}
                                        </BodyText>
                                        <DollarField
                                            formik={formik}
                                            name={"requestedHighestTicket"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
