import { stepsToStepViewMap } from "app/components/ProgressBlock";
import { AppRoutes } from "app/routes";
import { determineIsPayments } from "features/application-wizard";
import {
    InactiveStepName,
    StepName,
    StepStatus,
    stepToRoute,
    StepView,
} from "features/common";
import { AppStore } from "features/store";
import { FormikStore } from "features/store/FormikStore";
import { API_ENDPOINTS, fetchConfig, httpCore } from "features/utils";

import { IAppStateType } from "../AppStore.types";
import { defaultReducer } from "../defaultState";
import { getStepperState } from "../helpers";

export const commonReducers = {
    setProgressBlockSteps(
        state: IAppStateType,
        steps: StepView[],
        activeStep = 0,
    ) {
        return {
            ...state,
            progressBlock: {
                ...state.progressBlock,
                steps,
                activeStep,
            },
        };
    },
};

export const commonAsyncReducers = {
    async getHealthz(currentState: IAppStateType) {
        const res = await httpCore.get(`/healthz/`);
        if (res?.status === 200) {
            console.log("Backend is healthy!");
        }
        return defaultReducer;
    },

    async setConfig(
        currentState: IAppStateType,
        {
            step,
            token,
        }: {
            step?: StepName;
            token?: string | null;
        },
    ): Promise<typeof defaultReducer> {
        let {
            config: {
                isConfigLoaded,
                opportunityType,
                opportunityId,
                isRestaurant,
                isPayments,
                isSubmitted,
                businessName,
                businessRebookAction,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                clickToSign,
                welcomeSetup,
            },
            profile,
            // eslint-disable-next-line prefer-const
            businessStep: { typeOfOwnership },
        } = currentState;

        const data = await fetchConfig(token);

        const currentStep =
            step && data.stepper.availableSteps.includes(step)
                ? step
                : data.stepper.currentStep;

        const stepStatuses = data.stepper.steps.reduce(
            (prev, { stepName, stepStatus }) => ({
                ...prev,
                [stepName]: stepStatus,
            }),
            {} as Record<StepName | InactiveStepName, StepStatus>,
        );

        isConfigLoaded = true;
        opportunityType = data.opportunityType;
        opportunityId = data.opportunityId;
        isRestaurant = data.isRestaurant;
        isPayments = determineIsPayments(data.opportunityType);
        profile = data.user;
        businessName = data.businessName;
        businessRebookAction = data.businessRebookAction;
        isSubmitted = data.isApplicationSubmitted;
        clickToSign = data.clickToSign;
        welcomeSetup = data.welcomeSetup ?? {};
        let activeStepView: StepView;
        const stepsViews: StepView[] = data.stepper.steps.map((step, index) => {
            const mappedSteps = stepsToStepViewMap();
            const { subStep, ...rest } = mappedSteps[step.stepName] || {};
            // eslint-disable-next-line @typescript-eslint/naming-convention

            const stepView = {
                ...step,
                stepOrder: index,
                status: step.stepStatus,
                route: stepToRoute(step.stepName),
                name: step.stepName,
                subStep:
                    subStep ||
                    !Object.keys(stepsToStepViewMap()).includes(step.stepName)
                        ? { ...(subStep || { beforeParentStep: true }) }
                        : undefined,
                ...rest,
            };
            if (stepView.stepName === currentStep) {
                activeStepView = stepView;
            }
            return stepView;
        });

        return (state: IAppStateType) => ({
            ...state,
            config: {
                isConfigLoaded,
                opportunityType,
                opportunityId,
                isRestaurant,
                isPayments,
                isSubmitted,
                businessName,
                businessRebookAction,
                clickToSign,
                welcomeSetup,
            },
            profile: {
                ...state.profile,
                ...profile,
            },
            progressBlock: {
                ...state.progressBlock,
                initialRoute: data.stepper.currentStep
                    ? stepToRoute(data.stepper.currentStep)
                    : AppRoutes.Home,
                view: {
                    steps: stepsViews,
                    activeStep: activeStepView,
                },
                navigation: {
                    ...state.progressBlock.navigation,
                    availableStepsNames: data.stepper.availableSteps,
                    latestStepName:
                        data.stepper.availableSteps[
                            data.stepper.availableSteps.length - 1
                        ],
                    activeStepName: currentStep,
                    next: data.stepper.nextStep,
                    previous: data.stepper.previousStep,
                },
            },
            platformStep: {
                ...state.platformStep,
                stepStatus: stepStatuses[InactiveStepName.Platform],
            },
            businessStep: {
                ...state.businessStep,
                typeOfOwnership,
                stepStatus: stepStatuses[StepName.Business],
            },
            applicantStep: {
                ...state.applicantStep,
                stepStatus: stepStatuses[StepName.Applicant],
            },
            ownersStep: {
                ...state.ownersStep,
                stepStatus: stepStatuses[StepName.Owners],
            },
            shippingStep: {
                ...state.shippingStep,
                stepStatus: stepStatuses[StepName.Shipping],
            },
            almostDoneStep: {
                ...state.almostDoneStep,
                stepStatus: stepStatuses[StepName.AlmostDone],
            },
            fasterFunding: {
                ...state.almostDoneStep,
                stepStatus: stepStatuses[StepName.FasterFunding],
            },
            signatureStep: {
                ...state.signatureStep,
                wasSigned: data.isApplicationSubmitted,
            },
        });
    },

    async completeActiveStep(
        currentState: IAppStateType,
        appStore: AppStore,
        formikStore?: FormikStore,
    ) {
        const { activeStep } = currentState.progressBlock.view;
        let error: unknown;

        try {
            if (formikStore) {
                if (activeStep?.onSubmit) {
                    await activeStep.onSubmit({ appStore, formikStore });
                }
                error = undefined;
            }
        } catch (e) {
            error = e;
        }

        return (state: IAppStateType) => ({
            ...state,
            progressBlock: {
                ...state.progressBlock,
                error,
            },
        });
    },

    async submitAndGoForward(
        currentState: IAppStateType,
        appStore: AppStore,
        formikStore?: FormikStore,
    ) {
        return this.completeActiveStep(currentState, appStore, formikStore);
    },

    async setStep(currentState: IAppStateType, step: StepName) {
        try {
            const response = await httpCore.put(API_ENDPOINTS.common.SetStep, {
                currentStep: step,
            });
            const stepper = response.data;

            return (state: IAppStateType) => ({
                ...state,
                ...getStepperState({ state, stepper }),
            });
        } catch (err) {
            throw Error(err instanceof Error ? err.message : String(err));
        }
    },

    async goBack(currentState: IAppStateType, stepName?: StepName) {
        let previousStep;
        if (stepName) {
            previousStep = stepName;
        } else {
            previousStep = currentState.progressBlock.navigation.previous;
        }
        if (previousStep) return this.setStep(currentState, previousStep);
        else return defaultReducer;
    },
};
