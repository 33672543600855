import React from "react";
import { BodyText, Button } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { Modal, ModalFooter, ModalHeader } from "app/components";
import { AnalyticElements, getAnalyticProps } from "app/utils";
import { useTranslation } from "i18nConfig";

import { IConfirmationModalProps } from "./ConfirmationModal.types";

import styles from "./ConfirmationModal.module.scss";

export const ConfirmationModal = (props: IConfirmationModalProps) => {
    const { t } = useTranslation();
    const {
        shouldShowConfirmationModal,
        setShouldShowConfirmationModal,
        onConfirm,
        modalTitle,
        modalDescription,
        confirmButtonLabel,
        cancelButtonLabel = t("cancelButtonLabel"),
        isDanger = true,
        customOnCancelButton = () => setShouldShowConfirmationModal(false),
    } = props;

    return (
        <Modal
            isOpen={shouldShowConfirmationModal}
            onRequestClose={() => setShouldShowConfirmationModal(false)}
            overlayClassName={styles.ConfirmationModal}
            headerComponent={
                <ModalHeader
                    content={modalTitle}
                    onRequestClose={() => setShouldShowConfirmationModal(false)}
                />
            }
            footerComponent={
                <ModalFooter className="flex flex-col gap-2 mt-8">
                    <Button
                        onClick={onConfirm}
                        className={clsx(
                            styles.Modal_button,
                            isDanger && "!bg-danger-danger-70",
                        )}
                        data-testid="approve-confirmation-btn"
                        {...getAnalyticProps(
                            AnalyticElements.ApproveConfirmationButton,
                            {
                                context: "confirmationModal",
                            },
                        )}
                    >
                        {confirmButtonLabel}
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={customOnCancelButton}
                        className={styles.Modal_button}
                        data-testid="cancel-confirmation-btn"
                        {...getAnalyticProps(
                            AnalyticElements.CancelConfirmationButton,
                            {
                                context: "confirmationModal",
                            },
                        )}
                    >
                        {cancelButtonLabel}
                    </Button>
                </ModalFooter>
            }
        >
            <BodyText size="small" className="text-base-base-90">
                {modalDescription}
            </BodyText>
        </Modal>
    );
};
