import React from "react";
import { BodyText } from "@spotoninc/nexus-react";
import { Icon } from "spoton-lib";

import { Modal, ModalHeader } from "app/components";
import { useTranslation } from "i18nConfig";

import styles from "./ResponsiblePartyInfoModal.module.scss";

interface IResponsiblePartyInfoModalProps {
    shouldShowResponsiblePartyInfoModal: boolean;
    setShouldShowResponsiblePartyInfoModal: (value: boolean) => void;
}

export const ResponsiblePartyInfoModal = ({
    shouldShowResponsiblePartyInfoModal,
    setShouldShowResponsiblePartyInfoModal,
}: IResponsiblePartyInfoModalProps) => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={shouldShowResponsiblePartyInfoModal}
            onRequestClose={() => setShouldShowResponsiblePartyInfoModal(false)}
            overlayClassName={styles.ResponsiblePartyInfoModal}
            headerComponent={
                <ModalHeader
                    content={
                        <div
                            className={
                                styles.ResponsiblePartyInfoModal_headerWrapper
                            }
                        >
                            <div
                                className={
                                    styles.ResponsiblePartyInfoModal_headerTitle
                                }
                            >
                                {t("responsiblePartyInfoModalTitle")}
                            </div>
                            <Icon name="InformativeIcon" size={27} />
                        </div>
                    }
                    onRequestClose={() =>
                        setShouldShowResponsiblePartyInfoModal(false)
                    }
                />
            }
        >
            <BodyText size="small" className="text-base-base-90">
                {t("responsiblePartyTooltip")}
            </BodyText>
        </Modal>
    );
};
