import React, { useState } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "@spotoninc/nexus-react";

import { useTranslation } from "i18nConfig";

export const ConfirmModal = ({
    isOpen,
    onClose,
    onAccept,
    cancelLabel,
    acceptLabel,
    title,
    description,
}: {
    isOpen: boolean;
    onClose: () => void;
    onAccept: (value: unknown) => void;
    cancelLabel: string;
    acceptLabel: string;
    title: string;
    description: string;
}) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            className="tablet:max-w-[500px] absolute bottom-0 tablet:static w-full"
        >
            <ModalHeader>
                <span className="text-2xl">{title}</span>
            </ModalHeader>
            <ModalBody>{description}</ModalBody>
            <ModalFooter>
                <Button variant="tonal" onClick={onClose} disabled={isLoading}>
                    {cancelLabel && t("cancelButtonLabel")}
                </Button>
                <Button
                    onClick={() => {
                        setIsLoading(true);
                        onAccept(true);
                    }}
                    isLoading={isLoading}
                >
                    {acceptLabel}
                </Button>
            </ModalFooter>
        </Modal>
    );
};
