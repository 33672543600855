import React from "react";
import { BodyText } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { useTranslation } from "i18nConfig";

import styles from "./BillingSelectorRoot.module.scss";

export const UploadDescription = () => {
    const { t } = useTranslation();
    return (
        <div className={styles.FileUploadTexts}>
            <BodyText
                size="small"
                className={clsx(
                    "text-base-base-70 !text-xs block text-left gap-1 flex flex-wrap",
                )}
            >
                <span>{t("fileUploadSectionDescription")}</span>
                <span>{t("fileUploadSectionMaxSize", { maxSize: 10 })}</span>
            </BodyText>
        </div>
    );
};
