import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { Form, Formik, FormikProps } from "formik";

import { IShippingStep } from "features/application-wizard";
import { ShippingSubLabel } from "features/application-wizard/components";
// types
import { IFormProps } from "features/application-wizard/components/business/BusinessInformationForm";
import {
    ISelectValue,
    PhoneInputField,
    RadioField,
    ScrollToFieldError,
    SelectField,
    StepName,
    TextField,
    ZipCodeField,
} from "features/common";
import { countriesAndStates } from "features/common/constants";
import { useCountrySelect } from "features/common/hooks";
import { ShippingStepAdjustMode, shippingStepFormAdjust } from "features/utils";
import { useTranslation } from "i18nConfig";
import { useDerivedState } from "store/hooks";

import { ConfirmTax } from "./subcomponents/ConfirmTax";
import { InstallationTimeframe } from "./ShippingInformationForm.types";
import { shippingInformationValidationSchema } from "./ShippingInformationForm.utils";

import styles from "./ShippinginformationForm.module.scss";

// Component
export const ShippingInformationForm = ({
    appStore,
    formikStore,
}: IFormProps) => {
    // translations
    const { t } = useTranslation();

    // create formik ref and add to store
    const formRef = useRef<FormikProps<IShippingStep> | null>(null);
    useEffect(() => {
        // create formik ref and add to formikStore
        formikStore.dispatch.addRef(StepName.Shipping, formRef);
        return () => {
            formikStore.dispatch.removeRef(StepName.Shipping);
        };
    }, []);

    // get businessStep from appStore and save to componentState
    const [getState] = useDerivedState(
        appStore,
        ({
            data: { shippingStep, businessStep },
            loading: { isCompleteActiveStepLoading },
        }) => ({
            businessStep,
            shippingStep,
            isLoading: isCompleteActiveStepLoading,
        }),
    );
    const { businessStep, shippingStep, isLoading } = getState();

    const [shippingStateOptions, handleShippingCountrySelect] =
        useCountrySelect({
            value: shippingStep.shippingAddress.country || "US",
            label: "",
        });

    const onSubmit = async (values: IShippingStep) => {
        await appStore.asyncDispatch.updateShippingStep(values);
    };

    // whenever shippingStep is updated,
    // we should make sure the state options and synced fields are updated
    useEffect(() => {
        if (!isLoading) {
            formRef.current?.setValues(
                shippingStepFormAdjust(
                    shippingStep,
                    ShippingStepAdjustMode.FormIn,
                ),
            );
        }
    }, [shippingStep]);

    return (
        <div className={styles.ShippingInformationForm}>
            <Formik
                innerRef={formRef}
                initialValues={shippingStepFormAdjust(
                    shippingStep,
                    ShippingStepAdjustMode.FormIn,
                )}
                validationSchema={() =>
                    shippingInformationValidationSchema(
                        t,
                        !!formRef.current?.values?.isShippingSameAsBusiness,
                    )
                }
                onSubmit={async (values: IShippingStep) => {
                    await onSubmit(
                        shippingStepFormAdjust(
                            values,
                            ShippingStepAdjustMode.FormOut,
                        ),
                    );
                }}
                enableReinitialize={false}
            >
                {(formik: FormikProps<IShippingStep>) => {
                    return (
                        <Form>
                            <ScrollToFieldError formik={formik} />
                            <div className={clsx(styles.SectionWrapper)}>
                                <h5
                                    className={
                                        styles.ShippingInformationForm_title
                                    }
                                >
                                    {t("shippingInformationWhereShouldWeShip")}
                                </h5>
                                <div
                                    className={clsx(
                                        styles.RadioSection,
                                        "flex items-start",
                                    )}
                                >
                                    <div>
                                        <RadioField
                                            formik={formik}
                                            className={clsx(
                                                styles.RadioSection_label,
                                            )}
                                            name="isShippingSameAsBusiness"
                                            label={t(
                                                "shippingSameAsBusinessLabel",
                                            )}
                                            checked={
                                                formik.values
                                                    .isShippingSameAsBusiness
                                            }
                                            value={"yes"}
                                            onChange={() => {
                                                formRef.current?.setFieldValue(
                                                    "isShippingSameAsBusiness",
                                                    true,
                                                );
                                            }}
                                        />
                                        <ShippingSubLabel
                                            businessStep={businessStep}
                                        />
                                    </div>
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.RadioSection_label,
                                        )}
                                        name="isShippingSameAsBusiness"
                                        label={t("shippingOtherLabel")}
                                        checked={
                                            !formik.values
                                                .isShippingSameAsBusiness
                                        }
                                        value={"no"}
                                        onChange={() => {
                                            formRef.current?.setFieldValue(
                                                "isShippingSameAsBusiness",
                                                false,
                                            );
                                        }}
                                    />
                                </div>
                                {!formik.values.isShippingSameAsBusiness && (
                                    <div className={styles.SectionWrapper}>
                                        <TextField
                                            className={
                                                styles.ShippingInformationForm_fullRowInput
                                            }
                                            formik={formik}
                                            name="shippingAddress.address1"
                                            label={t("shippingAddress1Label")}
                                            value={
                                                formik.values?.shippingAddress
                                                    ?.address1 || ""
                                            }
                                        />
                                        <TextField
                                            className={
                                                styles.ShippingInformationForm_fullRowInput
                                            }
                                            formik={formik}
                                            name="shippingAddress.address2"
                                            label={t("shippingAddress2Label")}
                                            value={
                                                formik.values?.shippingAddress
                                                    ?.address2 || ""
                                            }
                                            primaryCondition={`(${t(
                                                "optionalLabel",
                                            )})`}
                                        />
                                        <div
                                            className={
                                                styles.ShippingInformationForm_row
                                            }
                                        >
                                            <div
                                                className={clsx(
                                                    styles.ShippingInformationForm_row,
                                                    styles.ShippingInformationForm_row___subRow,
                                                    styles.ShippingInformationForm_row___fullWidthM,
                                                )}
                                            >
                                                <TextField
                                                    className={clsx(
                                                        styles.ShippingInformationForm_input,
                                                        styles.ShippingInformationForm_input___subRow,
                                                        styles.ShippingInformationForm_input___fullWidthM,
                                                    )}
                                                    formik={formik}
                                                    name="shippingAddress.city"
                                                    label={t("cityLabel")}
                                                    value={
                                                        formik.values
                                                            ?.shippingAddress
                                                            ?.city
                                                    }
                                                />
                                                <SelectField
                                                    formik={formik}
                                                    className={clsx(
                                                        styles.ShippingInformationForm_input,
                                                        styles.ShippingInformationForm_input___subRow,
                                                        styles.ShippingInformationForm_input___fullWidthM,
                                                    )}
                                                    name="shippingAddress.state"
                                                    label={t("stateLabel")}
                                                    value={
                                                        formik.values
                                                            ?.shippingAddress
                                                            ?.state || ""
                                                    }
                                                    options={
                                                        shippingStateOptions
                                                    }
                                                    onChange={(
                                                        e: ISelectValue,
                                                    ) => {
                                                        formik.setFieldValue(
                                                            "shippingState",
                                                            e.value,
                                                        );
                                                    }}
                                                    placeholder={t(
                                                        "statePlaceholder",
                                                    )}
                                                />
                                            </div>
                                            <div
                                                className={clsx(
                                                    styles.ShippingInformationForm_row,
                                                    styles.ShippingInformationForm_row___smallSubRow,
                                                )}
                                            >
                                                <ZipCodeField
                                                    formik={formik}
                                                    className={clsx(
                                                        styles.ShippingInformationForm_input,
                                                        styles.ShippingInformationForm_input___subRow,
                                                    )}
                                                    name="shippingAddress.zip"
                                                    label={t("zipLabel")}
                                                    value={
                                                        formik.values
                                                            ?.shippingAddress
                                                            ?.zip
                                                    }
                                                />
                                                <SelectField
                                                    formik={formik}
                                                    className={clsx(
                                                        styles.ShippingInformationForm_input,
                                                        styles.ShippingInformationForm_input___subRow,
                                                    )}
                                                    name="shippingAddress.country"
                                                    label={t("countryLabel")}
                                                    options={countriesAndStates}
                                                    value={
                                                        formik.values
                                                            ?.shippingAddress
                                                            ?.country || "US"
                                                    }
                                                    onChange={(
                                                        e: ISelectValue,
                                                    ) => {
                                                        handleShippingCountrySelect(
                                                            e,
                                                        );
                                                        if (
                                                            e.value !==
                                                            formik.values
                                                                ?.shippingAddress
                                                                ?.country
                                                        ) {
                                                            formik.setFieldValue(
                                                                "shippingState",
                                                                "",
                                                            );
                                                        }
                                                    }}
                                                    isDisabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={clsx(styles.SectionWrapper)}>
                                <h5
                                    className={
                                        styles.ShippingInformationForm_title
                                    }
                                >
                                    {t("shippingInformationWhenInstallation")}
                                </h5>
                                <div className={clsx(styles.RadioSection)}>
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.RadioSection_label,
                                        )}
                                        name="installationTimeframe"
                                        label={t("installationImmediately")}
                                        checked={
                                            formik.values
                                                .installationTimeframe ===
                                            InstallationTimeframe.Immediately
                                        }
                                        value={
                                            InstallationTimeframe.Immediately
                                        }
                                    />
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.RadioSection_label,
                                        )}
                                        name="installationTimeframe"
                                        label={t("installationWithinMonth")}
                                        checked={
                                            formik.values
                                                .installationTimeframe ===
                                            InstallationTimeframe.WithinMonth
                                        }
                                        value={
                                            InstallationTimeframe.WithinMonth
                                        }
                                    />
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.RadioSection_label,
                                        )}
                                        name="installationTimeframe"
                                        label={t(
                                            "installationWithin2and3Months",
                                        )}
                                        checked={
                                            formik.values
                                                .installationTimeframe ===
                                            InstallationTimeframe.Within2and3Months
                                        }
                                        value={
                                            InstallationTimeframe.Within2and3Months
                                        }
                                    />
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.RadioSection_label,
                                        )}
                                        name="installationTimeframe"
                                        label={t(
                                            "installationWithin3and6Months",
                                        )}
                                        checked={
                                            formik.values
                                                .installationTimeframe ===
                                            InstallationTimeframe.Within3and6Months
                                        }
                                        value={
                                            InstallationTimeframe.Within3and6Months
                                        }
                                    />
                                    <RadioField
                                        formik={formik}
                                        className={clsx(
                                            styles.RadioSection_label,
                                        )}
                                        name="installationTimeframe"
                                        label={t("installationOver6Months")}
                                        checked={
                                            formik.values
                                                .installationTimeframe ===
                                            InstallationTimeframe.Over6Months
                                        }
                                        value={
                                            InstallationTimeframe.Over6Months
                                        }
                                    />
                                </div>
                            </div>
                            <div className={clsx(styles.SectionWrapper)}>
                                <h5
                                    className={clsx(
                                        styles.ShippingInformationForm_titleWithPadding,
                                    )}
                                >
                                    {t("installationTitle")}
                                </h5>
                                <div
                                    className={
                                        styles.ShippingInformationForm_row
                                    }
                                >
                                    <TextField
                                        className={
                                            styles.ShippingInformationForm_input
                                        }
                                        formik={formik}
                                        name="installationContact.firstName"
                                        label={t("firstNameLabel")}
                                        value={
                                            formik.values?.installationContact
                                                ?.firstName
                                        }
                                    />
                                    <TextField
                                        className={
                                            styles.ShippingInformationForm_input
                                        }
                                        formik={formik}
                                        name="installationContact.lastName"
                                        label={t("lastNameLabel")}
                                        value={
                                            formik.values?.installationContact
                                                ?.lastName
                                        }
                                    />
                                </div>
                                <div
                                    className={
                                        styles.ShippingInformationForm_row
                                    }
                                >
                                    <TextField
                                        className={
                                            styles.ShippingInformationForm_input
                                        }
                                        formik={formik}
                                        name="installationContact.email"
                                        label={t("emailLabel")}
                                        value={
                                            formik.values?.installationContact
                                                ?.email
                                        }
                                        inputMode="email"
                                        autoCapitalize="off"
                                    />
                                    <PhoneInputField
                                        className={
                                            styles.ShippingInformationForm_input
                                        }
                                        formik={formik}
                                        name="installationContact.phone"
                                        label={t("phoneLabel")}
                                        value={
                                            formik.values?.installationContact
                                                ?.phone
                                        }
                                    />
                                </div>
                            </div>
                            <ConfirmTax formik={formik} appStore={appStore} />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
