import React, { useContext } from "react";
import { isMobile } from "react-device-detect";
import { BodyText, Button } from "@spotoninc/nexus-react";
import clsx from "clsx";

import photoFrontRestaurant from "app/images/restaurant-back.webp";
import photoBackRestaurant from "app/images/restaurant-front.webp";
import photoFrontRetail from "app/images/retail-back.webp";
import photoBackRetail from "app/images/retail-front.webp";
import { Breakpoint, useBreakpoint } from "features";
import { useTranslation } from "i18nConfig";

import { SiteSurveyContext } from "../../SiteSurveyPage.utils";

import styles from "./SiteSurveyWelcomePage.module.scss";

export const SiteSurveyWelcomePage = ({
    onContinue,
}: {
    onContinue: () => void;
}) => {
    const { t } = useTranslation();
    const { aeName, businessName, isRestaurant } =
        useContext(SiteSurveyContext) || {};
    const isLowerThanTablet = useBreakpoint(Breakpoint.LowerThanTablet);
    const [photoFront, photoBack] = isRestaurant
        ? [photoFrontRestaurant, photoBackRestaurant]
        : [photoFrontRetail, photoBackRetail];

    return (
        <div className={styles.Container}>
            <div className={clsx(styles.Content)}>
                <main className={clsx(styles.Main)}>
                    <div className={styles.Main___title}>
                        {isMobile ? (
                            <h3 className="text-[30px] tablet:text-[48px] leading-[40px] tablet:leading-[56px] whitespace-pre-line">
                                {t("siteSurveyWelcomeTitle", {
                                    aeName,
                                    businessName,
                                })}
                            </h3>
                        ) : (
                            <h1 className="text-[30px] tablet:text-[48px] leading-[40px] tablet:leading-[56px] whitespace-pre-line">
                                {t("siteSurveyWelcomeTitle", {
                                    aeName,
                                    businessName,
                                })}
                            </h1>
                        )}
                        <BodyText size="small" className={styles.Main_subtitle}>
                            {t("siteSurveyWelcomeDescription")}
                        </BodyText>
                    </div>
                    <div className={styles.Main___images}>
                        <div className={styles.Main_imageContainer}>
                            <img
                                className={clsx(
                                    styles.Main_backImage,
                                    styles.Main_image,
                                )}
                                src={photoFront}
                                alt="homepageFrontImage"
                            />
                            <img
                                className={clsx(
                                    styles.Main_frontImage,
                                    styles.Main_image,
                                )}
                                src={photoBack}
                                alt="homepageBackImage"
                            />
                        </div>
                    </div>
                    {!isLowerThanTablet && (
                        <div className={styles.Main___button}>
                            <BodyText
                                className={styles.List_item}
                                size="medium"
                                as="li"
                            >
                                <Button
                                    onClick={onContinue}
                                    className={styles.Link}
                                    id={`${name}-task`}
                                >
                                    <span
                                        className={clsx(
                                            styles.Link_text,
                                            "whitespace-nowrap",
                                        )}
                                    >
                                        {t("getStartedButton")}
                                    </span>
                                </Button>
                            </BodyText>
                        </div>
                    )}
                </main>
            </div>
        </div>
    );
};
