import React, { HTMLAttributes, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@spotoninc/nexus-react";
import clsx from "clsx";

import { DeleteIcon } from "app/icons";

import { TaxPreview } from "../../ShippingInformationForm.types";
import { CommonModal } from "./CommonModal.component";
import { formatPercent } from "./helpers";

type AdditionalTaxItemProps = HTMLAttributes<HTMLDivElement> & {
    tax: TaxPreview;
    onDelete?: (tax: TaxPreview) => Promise<void>;
    isLoading?: boolean;
};

export const AdditionalTaxItem = (props: AdditionalTaxItemProps) => {
    const { tax, onDelete, isLoading, className, ...restProps } = props;
    const { t } = useTranslation();

    const [isRemoveModalVisible, setIsRemoveModalVisible] = useState(false);
    const onClose = () => setIsRemoveModalVisible(false);
    const onOpen = () => setIsRemoveModalVisible(true);
    return (
        <div
            className={clsx("flex justify-between items-center", className)}
            {...restProps}
        >
            <span>{tax.name}</span>
            <div className="flex gap-3 items-center">
                <span>{formatPercent(tax.rate)}</span>
                {onDelete && (
                    <>
                        <CommonModal
                            isOpen={isRemoveModalVisible}
                            onClose={onClose}
                            title={t("removeTaxTitle")}
                            confirmButtonLabel={t("deleteButtonLabel")}
                            onConfirm={async () => {
                                await onDelete(tax);
                                onClose();
                            }}
                            isConfirmLoading={isLoading}
                        >
                            <span>
                                {t("removeTaxConfirmation", { name: tax.name })}
                            </span>
                        </CommonModal>
                        <Button
                            className="flex items-center p-0"
                            variant="text"
                            type="button"
                            onClick={onOpen}
                        >
                            <DeleteIcon />
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
